import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
const PrivateRouteAdmin = ({ Component }) => {
  const [token] = useState(localStorage.getItem('access_token'))
  return token ? <Component /> : <Navigate to="/" />
}

export default PrivateRouteAdmin
