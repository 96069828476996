import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  breadcrumb: [],
}

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumb(state, action) {
      state.breadcrumb = action.payload
    },
    setBreadcrumbItem(state, action) {
      const isIncluded =
        state.breadcrumb.findIndex((item) => item?.name === action.payload?.name) !== -1

      if (isIncluded) {
        state.breadcrumb = state.breadcrumb.filter((item) => item?.name !== action?.payload?.name)
      } else {
        state.breadcrumb = [...state.breadcrumb, action.payload]
      }
    },
    resetState() {
      return initialState
    },
  },
})

export const breadcrumbActions = breadcrumbSlice.actions

export default breadcrumbSlice.reducer
